/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div style={{
        height: `100vh`,
        display: `flex`,
        flexDirection: `column`,
      }}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main style={{
          margin: `0 auto`,
          display: `flex`,
          flex: 1,
          flexDirection: `column`,
          justifyContent: `center`,
        }}>{children}</main>
        <footer style={{
          display: `flex`,
          selfAlign: `flex-end`,
          marginLeft: `1rem`
         }} >
          © {new Date().getFullYear()}, AWISE Soluções Tecnológicas LTDA.
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
