import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "../components/image"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
      display: `flex`,
      flexDirection: `row`
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        width: `50%`,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <Link
        to="/"
        style={{
          color: `#228c7b`,
          textDecoration: `none`,
        }}
      >
        <Image />
      </Link>
    </div>
    <div
      style={{
        margin: `0 auto`,
        width: `50%`,
        padding: `1.45rem 1.0875rem`,
        textAlign: `right`,
        marginTop: `10px`
      }}
    >
      <a href="https://app.convess.com"
        style={{
          textDecoration: `none`,
          padding: `0.5rem`,
          color: `white`,
          backgroundColor: `#228c7b`,
          fontSize: `small`,
          fontWeight: `600`,
          borderRadius: `2px`
        }}
      >
        ENTRAR
      </a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
